<!--
 * @Descripttion: 
 * @Author: luxiyuan
 * @Date: 2020-06-24 10:58:05
 * @LastEditors: faf
 * @LastEditTime: 2022-08-05 14:23:04
-->
<template>
  <div class="content bgFFF">
    <el-button type="text" @click="$router.go(-1)"> < 返回</el-button>
    <div class="pageTitle">商品详情</div>
    <el-form label-width="120px">
      <div class="section-title">基础信息</div>
      <el-row>
        <el-col :span="16">
          <el-form-item label="停车卡名称:">{{ formInline.name }}</el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="商品状态:">{{
            formInline.stateDesc
          }}</el-form-item>
        </el-col> -->
      </el-row>
      <!-- <el-form-item label="生效开始日期:" v-if="formInline.effectStartTime">{{
        formInline.effectStartTime | timeFilter(that)
      }}</el-form-item>
      <el-form-item label="生效结束日期:" v-if="formInline.effectEndTime">{{
        formInline.effectEndTime | timeFilter(that)
      }}</el-form-item>
      <el-form-item label="生效日期:" v-if="formInline.effectType === 1"
        >自购买{{ formInline.effectDaysAfterBuy }}天开始生效</el-form-item
      >
      <el-form-item label="有效天数:" v-if="formInline.effectType === 1"
        >有效天数{{ formInline.validityPeriod }}天</el-form-item
      > -->
      <el-form-item label="商户名称:">{{
        formInline.operationName
      }}</el-form-item>
      <el-form-item label="适用范围:">{{ formInline.scopeDesc }}</el-form-item>
      <el-form-item label="泊位车辆限制:"
        >{{ formInline.berthMaxNum || 0 }}泊位&nbsp;{{
          formInline.carMaxNum
        }}车</el-form-item
      >
      <el-form-item label="免费时间段:">
        <!-- <span v-for="(item, index) in formInline.freeTimeSlot" :key="index"
          >{{ typeList[item.type - 1] }}&nbsp;

          {{ item.allDay ? item.startTime + "-" + item.endTime : "全天" }}
        </span> -->
        {{ formInline.freeTimeSlotDesc }}
      </el-form-item>
      <el-form-item label="启用状态:">{{ formInline.stateDesc }}</el-form-item>
      <div class="section-title">销售信息</div>
      <!-- <el-form-item label="销售日期:"
        >{{ formInline.buyStartTime | timeFilter(that) }} 至
        {{ formInline.buyEndTime | timeFilter(that) }}</el-form-item
      > -->
      <el-form-item label="停车卡数量:">{{
        formInline.quantities | quantitiesFilter
      }}</el-form-item>
      <el-form-item label="线上销售:">{{
        formInline.appSaleDesc
      }}</el-form-item>
      <el-form-item label="销售价格:">{{
        (formInline.actualAmount / 100).toFixed(2) +
        "元/" +
        formInline.priceCycleDesc
      }}</el-form-item>
      <!-- <el-form-item label="销售渠道:">{{
        formInline.appSaleDesc
      }}</el-form-item> -->
      <!-- <el-row>
        <el-col :span="8">
          <el-form-item label="原价:"
            >{{ formInline.originalPrice | priceFilter }}元</el-form-item
          >
        </el-col>
        <el-col :span="8">
          <el-form-item label="优惠价格:"
            >{{ formInline.discountAmount | priceFilter }}元</el-form-item
          >
        </el-col>
        <el-col :span="8">
          <el-form-item label="销售价格:"
            >{{ formInline.actualAmount | priceFilter }}元</el-form-item
          >
        </el-col>
      </el-row> -->
      <el-form-item label="备注:">{{ formInline.remarks }}</el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "parkRuleDetail",
  filters: {
    timeFilter(value, that) {
      if (!value) return;
      return that.$moment(parseInt(value)).format("YYYY-MM-DD");
    },
    priceFilter(value) {
      if (!value) return 0;
      return (value / 100).toFixed(2);
    },
    quantitiesFilter(value) {
      if (!value) return "不限";
      return value + " 张";
    },
  },

  data() {
    return {
      formInline: { freeTimeSlot: [] },
      that: this,
      typeList: ["每天", "工作日", "节假日", "限号日"],
    };
  },
  mounted() {
    this.getParkRuleDetail();
  },
  methods: {
    // 获取详情信息
    getParkRuleDetail() {
      this.$axios
        .get("/acb/2.0/parkCardRule/detail", {
          data: {
            parkCardRuleId: this.$route.query.parkCardRuleId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
            this.formInline.freeTimeSlot = JSON.parse(
              this.formInline.freeTimeSlot
            );
            console.log(this.formInline.freeTimeSlot, "0000000");
            if (this.formInline.scopeDesc === "全部车场") {
              this.getScopes(res.value.operatorId);
            }
          }
        });
    },
    // 获取全部车场名称
    getScopes(operationId) {
      const opt = {
        url: "/acb/2.0/park/currentUserParkList",
        method: "get",
        data: {
          operationId,
        },
        success: (res) => {
          let arr = [];
          res.value.forEach((item) => {
            arr.push(item.parkName);
          });
          this.formInline.scopeDesc = arr.join("、");
        },
      };
      this.$request(opt);
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  padding: 20px;
}

.pageTitle {
  line-height: 30px;
  font-size: 16px;
}

.section-title {
  line-height: 35px;
  background-color: #ccc;
  color: #656565;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 20px;
}
</style>
