var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content bgFFF" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v(" < 返回")]
      ),
      _c("div", { staticClass: "pageTitle" }, [_vm._v("商品详情")]),
      _c(
        "el-form",
        { attrs: { "label-width": "120px" } },
        [
          _c("div", { staticClass: "section-title" }, [_vm._v("基础信息")]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-form-item", { attrs: { label: "停车卡名称:" } }, [
                    _vm._v(_vm._s(_vm.formInline.name)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "商户名称:" } }, [
            _vm._v(_vm._s(_vm.formInline.operationName)),
          ]),
          _c("el-form-item", { attrs: { label: "适用范围:" } }, [
            _vm._v(_vm._s(_vm.formInline.scopeDesc)),
          ]),
          _c("el-form-item", { attrs: { label: "泊位车辆限制:" } }, [
            _vm._v(
              _vm._s(_vm.formInline.berthMaxNum || 0) +
                "泊位 " +
                _vm._s(_vm.formInline.carMaxNum) +
                "车"
            ),
          ]),
          _c("el-form-item", { attrs: { label: "免费时间段:" } }, [
            _vm._v(" " + _vm._s(_vm.formInline.freeTimeSlotDesc) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "启用状态:" } }, [
            _vm._v(_vm._s(_vm.formInline.stateDesc)),
          ]),
          _c("div", { staticClass: "section-title" }, [_vm._v("销售信息")]),
          _c("el-form-item", { attrs: { label: "停车卡数量:" } }, [
            _vm._v(
              _vm._s(_vm._f("quantitiesFilter")(_vm.formInline.quantities))
            ),
          ]),
          _c("el-form-item", { attrs: { label: "线上销售:" } }, [
            _vm._v(_vm._s(_vm.formInline.appSaleDesc)),
          ]),
          _c("el-form-item", { attrs: { label: "销售价格:" } }, [
            _vm._v(
              _vm._s(
                (_vm.formInline.actualAmount / 100).toFixed(2) +
                  "元/" +
                  _vm.formInline.priceCycleDesc
              )
            ),
          ]),
          _c("el-form-item", { attrs: { label: "备注:" } }, [
            _vm._v(_vm._s(_vm.formInline.remarks)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }